import styled from "styled-components";
import { MaximizeIcon } from "../Icons/MaximizeIcon";
import { CrossIcon } from "../Icons/CrossIcon";

const ChatRoomHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 45px;
  border-radius: 8px 8px 0px 0px;
  background-color: #ebf0f5;
`;

const HeadingTitle = styled.h5`
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 20px;
`;

const IconsWrapperParent = styled.div`
  display: flex;
  justify-content: space-between;

  margin-right: 20px;
  width: 60px;
`;

const IconsWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  cursor: pointer;
`;

export const ChatHeader = (props) => {
  return (
    <ChatRoomHeader>
      <HeadingTitle>API Assistant</HeadingTitle>
      <IconsWrapperParent>
        <IconsWrapper onClick={() => props.toggleMaximise()}>
          <MaximizeIcon />
        </IconsWrapper>
        <IconsWrapper onClick={() => props.toggleChat()}>
          <CrossIcon />
        </IconsWrapper>
      </IconsWrapperParent>
    </ChatRoomHeader>
  );
};
