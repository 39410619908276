import styled from "styled-components";
import { BotIcon } from "../Icons/BotIcon";
import { useState } from "react";
import { ChatRoom } from "./ChatRoom";

const ChatBotPopup = styled.div`
  position: fixed;
  right: 24px;
  bottom: 73px;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 55px;
  width: 55px;
  background: #ff4451;
  border-radius: 50%;

  cursor: pointer;
`;

export const ChatBot = () => {
  const [isChatOpen, setIsChatOpen] = useState(false);

  const toggleChat = () => setIsChatOpen(!isChatOpen);
  return (
    <>
      <ChatBotPopup onClick={() => toggleChat()}>
        <BotIcon />
      </ChatBotPopup>
      {isChatOpen && <ChatRoom toggleChat={toggleChat} />}
    </>
  );
};
