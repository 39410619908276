import { useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { styled, keyframes } from "styled-components";
import { BotIconSmall } from "../Icons/BotIconSmall";
import { UserIcon } from "../Icons/UserIcon";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { nightOwl } from "react-syntax-highlighter/dist/esm/styles/prism";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CopyToClipboardIcon } from "../Icons/CopyToClipboard";

const MessagesWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const MessageParent = styled.div`
  width: 100%;
  height: fit-content;
  padding: 15px 0px;
  display: flex;
  justify-content: ${(props) => (props.isMaximised ? "center" : "flex-start")};
  background-color: ${(props) => (props.isUser ? "#fff" : "#F7F7F8")};
  -webkit-transition: 0.4s;
  transition: 0.4s;
`;
const IconWrapper = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: ${(props) => (props.isUser ? "#6B7E8F" : "#FF4451")};

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 18px;
`;

function blinkingEffect() {
  return keyframes`
    50% {
      opacity: 0;
    }
  `;
}

const ChatMessage = styled.div`
  padding-top: 5px;
  width: ${(props) => (props.isMaximised ? "60%" : "375px")};
  height: fit-content;
  animation: ${(props) => (props.shouldblink ? blinkingEffect : "none")} 1s
    linear infinite;

  > p:first-child {
    margin: 0px;
  }
`;

const SvgParentDiv = styled.div`
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 10px;

  &:hover {
    > svg {
      > path {
        stroke: #fff;
      }
    }
  }
`;

export const ChatMessages = (props) => {
  console.log("props in messages", props);
  var messageArray = [
    { role: "assistant", content: "How can i help you ?" },
    ...props.messages,
  ];

  if (messageArray[messageArray.length - 1].role === "user") {
    messageArray = [...messageArray, { role: "assistant", content: "_" }];
  }

  console.log();

  useEffect(() => {
    var wrapperObj = document.getElementById("messages-wrapper");
    wrapperObj.scrollTop = wrapperObj.scrollHeight;
  }, [props]);

  return (
    <MessagesWrapper id="messages-wrapper">
      {messageArray.map((message) => {
        const isUser = message.role === "user";
        return (
          <MessageParent isUser={isUser} isMaximised={props.isMaximised}>
            <IconWrapper isUser={isUser}>
              {isUser ? <UserIcon /> : <BotIconSmall />}
            </IconWrapper>
            <ChatMessage
              isMaximised={props.isMaximised}
              shouldblink={message.content === "_"}
            >
              <ReactMarkdown
                children={message.content}
                components={{
                  code({ node, inline, className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || "");

                    return !inline && match ? (
                      <div style={{ position: "relative" }}>
                        <SyntaxHighlighter
                          style={nightOwl}
                          PreTag="div"
                          language={match[1]}
                          children={String(children).replace(/\n$/, "")}
                          {...props}
                        />
                        <CopyToClipboard text={children}>
                          <SvgParentDiv>
                            <CopyToClipboardIcon />
                          </SvgParentDiv>
                        </CopyToClipboard>
                      </div>
                    ) : (
                      <code className={className ? className : ""} {...props}>
                        {children}
                      </code>
                    );
                  },
                }}
              />
            </ChatMessage>
          </MessageParent>
        );
      })}
    </MessagesWrapper>
  );
};
