import { Portal } from "./Components/Portal";
import { ChatBot } from "./Components/Chatbot";

const App = () => {
  return (
    <>
      <Portal />
      <ChatBot />
    </>
  );
};

export default App;
