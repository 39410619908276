export const UserIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M11.0833 12.25V11.0833C11.0833 10.4645 10.8375 9.871 10.3999 9.43342C9.96229 8.99583 9.3688 8.75 8.74996 8.75H5.24996C4.63112 8.75 4.03763 8.99583 3.60004 9.43342C3.16246 9.871 2.91663 10.4645 2.91663 11.0833V12.25"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.99996 6.41667C8.28862 6.41667 9.33329 5.372 9.33329 4.08333C9.33329 2.79467 8.28862 1.75 6.99996 1.75C5.71129 1.75 4.66663 2.79467 4.66663 4.08333C4.66663 5.372 5.71129 6.41667 6.99996 6.41667Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
