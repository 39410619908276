export const BotIconSmall = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <g clip-path="url(#clip0_1_2275)">
        <path
          d="M11.0833 6.41666H2.91667C2.27233 6.41666 1.75 6.939 1.75 7.58333V11.0833C1.75 11.7277 2.27233 12.25 2.91667 12.25H11.0833C11.7277 12.25 12.25 11.7277 12.25 11.0833V7.58333C12.25 6.939 11.7277 6.41666 11.0833 6.41666Z"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.00004 4.08333C7.64437 4.08333 8.16671 3.561 8.16671 2.91667C8.16671 2.27233 7.64437 1.75 7.00004 1.75C6.35571 1.75 5.83337 2.27233 5.83337 2.91667C5.83337 3.561 6.35571 4.08333 7.00004 4.08333Z"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7 4.08334V6.41667"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_2275">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
