import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { SubmitIcon } from "../Icons/SubmitIcon";

const SearchWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
`;

const Container = styled.div`
  position: relative;
`;

const SearchInput = styled.textarea`
  min-height: 40px;
  width: ${(props) => (props.isMaximised ? "60vw" : "420px")};
  line-height: 22px;
  border: 1px solid rgb(231, 233, 242);
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0px 0px 5px rgba(231, 233, 242, 0.9);
  padding: 7px 10px;
  font-family: inherit;
  font-size: 1em;
  margin: 10px 0px;
  -webkit-transition: 0.25s;
  transition: 0.25s;
  outline: none;
  resize: none;
  cursor: ${(props) => (props.isInputDisabled ? "not-allowed" : "text")};

  &:focus {
    border: 1px solid rgba(150, 150, 150);
  }
`;

const SubmitButton = styled.button`
  position: absolute;
  top: 50%;
  right: 2%;
  transform: translateY(-50%);
  border: none;
  background-color: transparent;
  padding: 0 5px;
  cursor: ${(props) => (props.isInputDisabled ? "not-allowed" : "pointer")};
`;

export const SearchBar = (props) => {
  const [prompt, setPrompt] = useState("");
  const textAreaRef = useRef(null);

  const resizeTextArea = () => {
    textAreaRef.current.style.height = "auto";
    textAreaRef.current.style.height = `${Math.min(
      textAreaRef.current.scrollHeight,
      130
    )}px`;
  };

  useEffect(resizeTextArea, [prompt]);

  const onEnterPress = (e) => {
    if (!prompt.length) {
      setPrompt("");
      return;
    }
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      onFormSubmit(e);
    }
  };

  const onFormSubmit = (event) => {
    event.preventDefault();
    const message = { role: "user", content: prompt };
    setPrompt("");
    props.onSubmit(message);
  };

  return (
    <SearchWrapper>
      <form id="submit-form">
        <Container>
          <SearchInput
            isInputDisabled={props.isInputDisabled}
            disabled={props.isInputDisabled}
            ref={textAreaRef}
            placeholder="Send a message"
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
            isMaximised={props.isMaximised}
            onKeyDown={onEnterPress}
            rows={1}
          />
          <SubmitButton
            onClick={onFormSubmit}
            disabled={props.isInputDisabled || !prompt.length}
            isInputDisabled={props.isInputDisabled || !prompt.length}
          >
            <SubmitIcon />
          </SubmitButton>
        </Container>
      </form>
    </SearchWrapper>
  );
};
