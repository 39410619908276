import styled from "styled-components";
import { ChatHeader } from "./ChatHeader";
import { useState } from "react";
import { ChatMessages } from "./ChatMessages";
import { SearchBar } from "./Search";

const ChatRoomParent = styled.div`
  position: fixed;
  right: ${(props) => (props.isMaximised ? "1vw" : "24px")};
  bottom: ${(props) => (props.isMaximised ? "5vh" : "125px")};

  display: flex;
  flex-direction: column;

  width: ${(props) => (props.isMaximised ? "98vw" : "480px")};
  height: ${(props) => (props.isMaximised ? "90vh" : "65vh")};
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);

  -webkit-transition: 0.5s;
  transition: 0.5s;
`;

export const ChatRoom = (props) => {
  let currentMessage = "";
  const [isMaximised, setIsMaximised] = useState(false);
  const [messages, setMessages] = useState([]);
  const [error, setError] = useState();
  const [isInputDisabled, setIsInputDisabled] = useState(false);

  const toggleMaximise = () => setIsMaximised(!isMaximised);

  const setDataBeforeAxiosCall = (prompt) => {
    const messagesClone =
      messages?.length !== 0 ? [...messages, prompt] : [prompt];
    setMessages((st) => messagesClone);
    onSearchSubmit(messagesClone);
  };

  const onSearchSubmit = async (completeChat) => {
    setIsInputDisabled(true);
    setMessages((prev) => [...prev, { role: "assistant", content: "" }]);
    const goodRequest = { messages: completeChat };

    try {
      const streamReader = (
        await fetch(
          "https://app-chatcompletionapi-dev-v2.azurewebsites.net/chat",
          {
            method: "POST",
            body: JSON.stringify(goodRequest), // Replace with your actual data
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
      ).body.getReader();

      const decoder = new TextDecoder();

      await streamReader
        .read()
        .then(async function processText({ done, value }) {
          if (done) {
            return;
          }

          const text = decoder.decode(value);
          currentMessage += text;
          console.log(text);
          setMessages((prevMessages) => {
            const lastMessageIndex = prevMessages.length - 1;

            // Make a copy of the previous messages array
            const updatedMessages = [...prevMessages];

            // Update the content and role of the last message
            updatedMessages[lastMessageIndex] = {
              ...updatedMessages[lastMessageIndex],
              role: "assistant",
              content: currentMessage,
            };

            return updatedMessages;
          });

          // Read some more, and call this function again
          return streamReader.read().then(processText);
        });

      setError("");
    } catch (err) {
      setMessages([]);
      setError(err.message);
    }
    setIsInputDisabled(false);
  };

  return (
    <ChatRoomParent isMaximised={isMaximised}>
      <ChatHeader
        toggleMaximise={toggleMaximise}
        toggleChat={props.toggleChat}
      />
      <ChatMessages
        messages={messages}
        isMaximised={isMaximised}
        errors={error}
      />
      <SearchBar
        isMaximised={isMaximised}
        isInputDisabled={isInputDisabled}
        onSubmit={setDataBeforeAxiosCall}
      />
    </ChatRoomParent>
  );
};
