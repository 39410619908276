import { useEffect } from "react";

export const Portal = () => {
  useEffect(() => {
    var script = document.createElement("script");
    script.src = "https://dxjs.apimatic.io/v7/static/js/portal.v7-uber.js";
    script.onload = function () {
      /*global APIMaticDevPortal */
      APIMaticDevPortal.show({
        container: "apimatic-widget",
        portalStyle: "default",
        codegenApiRoutes: {
          docsgen:
            "/api/api-entities/HvtAoEO3NCf3_B3ZCi5-yRe0NayLcGKIok3XZt2gWCVWt4gz_VXd_cbvAuJlb686/portal-artifacts/docs/generated-file?template={template}",
          codegen:
            "/api/api-entities/HvtAoEO3NCf3_B3ZCi5-yRe0NayLcGKIok3XZt2gWCVWt4gz_VXd_cbvAuJlb686/portal-artifacts/sdks/generated-file?template={template}",
          transform:
            "/api/api-entities/HvtAoEO3NCf3_B3ZCi5-yRe0NayLcGKIok3XZt2gWCVWt4gz_VXd_cbvAuJlb686/portal-artifacts/specs/generated-file?format={format}",
          apiProxy: "https://proxy.apimatic.io/api/proxy",
        },
        apiKey:
          "PkUYNyvjYA7DfFBwoKTjTOR1IHJBgYt1IVpv6xbAPqCouiWYIzGvPYi-HzQ-yghs0pG0gbXg46LM4tJMlgNexw**",
        baseUrl: "https://www.apimatic.io",
        enableExport: true,
        renameHttpToRest: false,
        enableConsoleCalls: true,
        useProxyForConsoleCalls: true,
        initialPlatform: "ts_generic_lib",
        languageSettings: {
          http_curl_v1: {
            disableSdkDownload: true,
          },
          cs_net_standard_lib: {
            disableSdkDownload: false,
            sdkDownloadLink: "",
          },
          java_eclipse_jre_lib: {
            disableSdkDownload: false,
            sdkDownloadLink: "",
          },
          php_generic_lib_v2: {
            disableSdkDownload: false,
            sdkDownloadLink: "",
          },
          python_generic_lib: {
            disableSdkDownload: false,
            sdkDownloadLink: "",
          },
          ruby_generic_lib: {
            disableSdkDownload: false,
            sdkDownloadLink: "",
          },
          ts_generic_lib: {
            disableSdkDownload: false,
            sdkDownloadLink: "",
          },
          go_generic_lib: {
            disableSdkDownload: false,
            sdkDownloadLink: "",
          },
        },
        allowedExportFormats: [
          "postman10",
          "postman20",
          "openapi31json",
          "openapi31yaml",
          "openapi3json",
          "openapi3yaml",
          "swagger20",
          "swaggeryaml",
          "swagger10",
          "raml",
          "raml10",
          "apiblueprint",
          "wadl2009",
          "apimatic",
          "wsdl",
          "insomnia",
          "insomniayaml",
        ],
        themeOverrides: {
          themeType: "cool",
          palette: {
            primaryColor: "#113984",
            secondaryColor: "#FFFFFF",
            linkColor: "#349de1",
          },
          fontSource: [
            "https://fonts.googleapis.com/css?family=Inter:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i",
            "https://fonts.googleapis.com/css?family=IBM+Plex+Mono:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i",
          ],
          cssStyles: {
            headings: {
              fontFamily: "Rubik, sans-serif",
              h1: {
                fontFamily: "Rubik, sans-serif",
                fontSize: "27px",
                fontWeight: "500",
                fontStyle: "normal",
                lineHeight: "0.9",
              },
              h2: {
                fontFamily: "Rubik, sans-serif",
                fontSize: "24px",
                fontWeight: "500",
                fontStyle: "normal",
                lineHeight: "0.82",
              },
              h3: {
                fontFamily: "Rubik, sans-serif",
                fontSize: "21.36px",
                fontWeight: "500",
                fontStyle: "normal",
                lineHeight: "1",
              },
              h4: {
                fontFamily: "Rubik, sans-serif",
                fontSize: "18px",
                fontWeight: "500",
                fontStyle: "normal",
                lineHeight: "1.17",
              },
              h5: {
                fontFamily: "Rubik, sans-serif",
                fontSize: "16px",
                fontWeight: "500",
                fontStyle: "normal",
                lineHeight: "1.26",
              },
              h6: {
                fontFamily: "Rubik, sans-serif",
                fontSize: "15px",
                fontWeight: "500",
                fontStyle: "normal",
                lineHeight: "1.35",
              },
            },
            body: {
              fontFamily: "Rubik, sans-serif",
              text1: {
                fontFamily: "Rubik, sans-serif",
                fontSize: "15px",
                fontWeight: "400",
                fontStyle: "normal",
                lineHeight: "1.78",
              },
              text2: {
                fontFamily: "Rubik, sans-serif",
                fontSize: "13.33px",
                fontWeight: "400",
                fontStyle: "normal",
                lineHeight: "1.5",
              },
              text3: {
                fontFamily: "Rubik, sans-serif",
                fontSize: "11.85px",
                fontWeight: "400",
                fontStyle: "normal",
                lineHeight: "1.66",
              },
            },
            code: {
              fontFamily: "Courier Prime, monospace",
              blockCode: {
                fontFamily: "Courier Prime, monospace",
                fontSize: "15px",
                fontWeight: "400",
                fontStyle: "normal",
                lineHeight: "1.66",
              },
              inlineCode: {
                fontFamily: "Courier Prime, monospace",
                fontSize: "15px",
                fontWeight: "400",
                fontStyle: "normal",
                lineHeight: "1.66",
              },
            },
          },
        },
      });
    };

    document.head.appendChild(script);
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <>
      <div
        style={{
          height: "7vh",
          display: "flex",
          alignItems: "center",
          marginLeft: "10px",
        }}
      >
        <span style={{ width: "80vw", display: "flex", alignItems: "center" }}>
          <span class="logo" style={{ height: "4vh" }}>
            <img
              src="https://apimaticio.blob.core.windows.net/images/draft-logos/paypal-c1f564e6-9e1b-49a9-8946-d29a0950b9f5.png"
              style={{ height: "4vh" }}
            />
          </span>
          <span
            style={{
              height: "4vh",
              borderLeft: "1px solid #d9dee5",
              margin: "0 15px",
            }}
          ></span>
          <span style={{ fontSize: "22px", fontWeight: "500" }}>Order API</span>
        </span>
        <span
          style={{
            width: "20vw",
            display: "flex",
            flexDirection: "row-reverse",
          }}
        >
          <span
            style={{
              alignItems: "center",
              fontSize: "11.85px",
              color: "rgb(165, 179, 187)",
              marginRight: "10px",
            }}
          >
            <a href="https://www.apimatic.io/" target="__blank">
              <img
                src="https://www.apimatic.io/imgs/apimatic-logo-horizontal.svg"
                style={{ height: "2.5vh" }}
              />
            </a>
          </span>
        </span>
      </div>
      <div
        id="apimatic-widget"
        className="page-content"
        style={{
          height: "93vh",
          width: "100vw",
        }}
      />
    </>
  );
};
